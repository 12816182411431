import React from 'react';
import { Box, Stack, styled, TextField, Typography } from "@mui/material";
import wave from '../assets/wave.svg'
import extension from '../assets/extension.svg'
import title from '../assets/localshirtTitle.svg'
import women from '../assets/women.svg'
import localscoreProcess from '../assets/calcul.svg'
import PartnerList from './PartnerList';
import bottomWave from '../assets/bottomWave.svg'
import NameCard from '../components/nameCard/nameCard';

import pictureAxel from "../assets/52727831819_146b9b47af_o.jpg";
import pictureTimothee from "../assets/IMG_7461.png";
import pictureJuliette from "../assets/FB_IMG_1712578105946.jpg";
import pictureErwan from "../assets/Downgraded.png";
import pictureMartin from "../assets/20240108_111538.jpg";
import pictureQuentin from "../assets/52727831819_146b9b47af_o (copy).jpg";
import pictureSpeedy from "../assets/speedy.jpg";
import TimeLine from "../components/TimeLine";
import Navbar from '../components/navbar/Navbar';

export const Center = styled(Box)<{ component?: React.ElementType }>(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const SearchInputField = styled(TextField)(({ theme }) => ({
    width: '100%', fieldset: {
        border: 'none'
    }, input: {
        color: theme.palette.gray.dark,
    },
}));

export const PresentationSentenceGray = styled(Typography)(({ theme }) => ({
    color: theme.palette.white.main,
    fontSize: 24,
    display: 'inline'
}));

export const PresentationSentenceGreen = styled(Typography)(({ theme }) => ({
    color: theme.palette.green.main,
    fontSize: 24,
    display: 'inline'
}));


const InformationPage = () => {
    return (
        <Box
        sx={{
            position: 'relative',
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'auto',
        }}
        >
            <Navbar/>
            <img
                src={wave}
                alt="Wave Background"
                style={{
                    position: 'absolute',
                    top: '500px',
                    width: '100vw',
                    zIndex: '-1',
                    overflowX: 'hidden',
                }}
            />
            <Box sx={{
                height: '75vh',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    flexDirection: 'column',
                    margin: '20px'
                }}>
                    <img src={title} style={{ marginBottom: '40px' }} alt="Title Image" />
                    <Typography variant="h2" sx={{ width: '30vw' }}>
                        Une mode
                        <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}> éthique</Box> à ton image : <br />
                        Trouvons le vêtement
                        <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}> éco-responsable </Box>
                        qui reflète ton style
                        <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}> unique</Box> !
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: 'column',
                    }} >
                    <img
                        src={extension}
                        alt="New Image"
                    />
                </Box>
            </Box>


            <Box sx={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
            }}>
                <Box sx={{ marginTop: '60px' }}>
                    <img
                        src={women}
                        alt="New Image"
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    flexDirection: 'column',
                    height: '50vh',
                    margin: '20px',
                }}>
                    <Typography variant="h1" color={'white.main'} fontWeight={'bold'} marginBottom={'10px'}>Le projet Localshirt</Typography>
                    <Typography variant="h2" color={'white.main'} sx={{ width: '55vw' }}>La mission de Localshirt est de sensibiliser les consommateurs sur
                        <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}> l'impact de l'industrie textile</Box>,
                        et de les encourager à faire des choix plus <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}> durables</Box>,
                        <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}> respectueux de l'environnement</Box>, et de l’être humain.</Typography>
                </Box>
            </Box>



            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10vh',

                }}>
                <img
                    src={localscoreProcess}
                    alt="New Image"
                />
            </Box>

            <Box sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <PartnerList></PartnerList>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                <img
                    src={bottomWave}
                    alt="Wave Background"
                    style={{
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: '-1',
                    }}
                />

                <Box
                    sx={{
                        paddingTop: '200px',
                        height: '150vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <NameCard photo={pictureAxel} isPhotoRight={false} text={<PresentationSentenceGray>Je suis <PresentationSentenceGreen>passionné</PresentationSentenceGreen>, <PresentationSentenceGreen>créatif</PresentationSentenceGreen> et <PresentationSentenceGreen>déterminé</PresentationSentenceGreen>, toujours prêt à explorer de nouvelles idées pour <PresentationSentenceGreen>inspirer</PresentationSentenceGreen> et <PresentationSentenceGreen>motiver</PresentationSentenceGreen> ceux qui m'entourent.</PresentationSentenceGray>} />
                    <NameCard photo={pictureQuentin} isPhotoRight={true} text={<PresentationSentenceGray>Hello ! Je m'appelle <PresentationSentenceGreen>Quentin Caniou</PresentationSentenceGreen> et je fais de mon mieux pour que vous puissiez trouver les <PresentationSentenceGreen>vêtements de vos rêves</PresentationSentenceGreen> grâce à <PresentationSentenceGreen>l'intelligence artificielle</PresentationSentenceGreen> !</PresentationSentenceGray>} />
                    <NameCard photo={pictureJuliette} isPhotoRight={false} text={<PresentationSentenceGray>Bonjour ! Je suis <PresentationSentenceGreen>Juliette Destang</PresentationSentenceGreen>, étudiante à Epitech et <PresentationSentenceGreen>passionnée</PresentationSentenceGreen> par le web et les paquebots. <PresentationSentenceGreen>Bienvenue</PresentationSentenceGreen> sur localshirt !</PresentationSentenceGray>} />
                    <NameCard photo={pictureTimothee} isPhotoRight={true} text={<PresentationSentenceGray><PresentationSentenceGreen>Timothée De Boynes</PresentationSentenceGreen>, toujours <PresentationSentenceGreen>curieux</PresentationSentenceGreen> et en quête de nouvelles technologies, je suis prêt à relever tous les défis pour <PresentationSentenceGreen>offrir</PresentationSentenceGreen> des expériences web <PresentationSentenceGreen>exceptionnelles</PresentationSentenceGreen>.</PresentationSentenceGray>} />
                    <NameCard photo={pictureMartin} isPhotoRight={false} text={<PresentationSentenceGray>Je suis un développeur backend passionné par la création de solutions robustes et performantes qui alimentent les coulisses invisibles mais essentielles des applications. Merci Chat GPT.</PresentationSentenceGray>} />
                    <NameCard photo={pictureErwan} isPhotoRight={true} text={<PresentationSentenceGray><PresentationSentenceGreen>Erwan Simonetti</PresentationSentenceGreen>, côté serveur, web-app ou devOps, <PresentationSentenceGreen>j'aime</PresentationSentenceGreen> relever les défis technologiques et <PresentationSentenceGreen>veille</PresentationSentenceGreen> à ce que chaque étape soit réalisée avec <PresentationSentenceGreen>soin</PresentationSentenceGreen> et <PresentationSentenceGreen>attention</PresentationSentenceGreen>.</PresentationSentenceGray>} />
                    <NameCard photo={pictureSpeedy} isPhotoRight={false} text={<PresentationSentenceGray>Bienvenue! Je travaille actuellement sur Localshirt en tant que dévelopeur backend</PresentationSentenceGray>} />
                </Box>
                <Box>
                    <TimeLine/>
                </Box>
            </Box>

        </Box>
    );
};

export default InformationPage;

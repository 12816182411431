import { Box, Button, Stack, Typography } from "@mui/material";
import { useCookies } from 'react-cookie';
import { Center } from "../../informationPage/Information";
import localShirtFullLogoGreen from "../../assets/green_logo.svg";
import whiteLogo from './../../assets/white_logo.svg';
import AccountButton from "../myAccount/accountButton";

export default function Navbar() {
    const [cookies, setCookie] = useCookies(['token', 'theme']);
    
    return (
        <Box width="100%" zIndex={1000} position="relative">
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                paddingY={'1.5vh'}
            >
                {/* Logo */}
                <Box>
                    <Button style={{ border: 'none', background: 'none' }} onClick={() => window.location.replace("/")}>
                        {window.location.pathname === "/catalog" ? (
                            <img
                                src={whiteLogo}
                                alt="White Logo"
                                style={{
                                    marginLeft: '290px',
                                    marginTop: "5px",
                                    width: '250px',
                                    height: 'auto',
                                }}
                            />
                        ) : (
                            <img
                                src={localShirtFullLogoGreen}
                                alt="Green Logo"
                                style={{ marginLeft: '2vw' }}
                            />
                        )}
                    </Button>
                </Box>

                {!cookies.token ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        marginRight={'2vw'}
                    >
                        {/* Espace Partenaire */}
                        <Typography
                            sx={{
                                marginRight: '2vw', 
                                ":hover": { cursor: "pointer" },
                                whiteSpace: 'nowrap',
                            }}
                            onClick={() => window.location.replace('/login')}
                            variant="h3"
                        >
                            Espace Partenaire
                        </Typography>

                        {/* Analyser un article */}
                        <Center
                            bgcolor={'green.main'}
                            borderRadius={'5px'}
                            width={'100%'}
                            height={'70%'}
                            paddingX={'1vw'}
                            onClick={() => window.location.replace('/login')}
                            sx={{ ":hover": { cursor: "pointer" } }}
                        >
                            <Typography variant={'button'} color={'white.light'}>Analyser un article</Typography>
                        </Center>
                    </Box>
                ) : (
                    <AccountButton />
                )}
            </Stack>
        </Box>
    )
}

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Forbidden from '../../components/forbidden/Forbidden';
import AlternativeCard from './alternativeCard';
import Loading from '../../components/loading/Loading';
import BlurryBackground from '../../components/blurryBackground/BlurryBackground';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { CLSThresholds } from 'web-vitals';


export type Items = {
    title: string;
    photo: string;
    id: string;
    price: number;
    desc: string;
    score: number;
    type: string;
    redirectnumber: number;
    lastshown: Date;
    lastclick: Date;
    ethicalscore: number;
    ecologicalscore: number;
    redirection_url: string;
};


export interface Article {
    id: string;
    name: string;
    brand: string;
    redirection_url: string;
    email: string;
    country: {
        name: string;
    };
    material: {
        name: string;
    };
    transport: {
        name: string;
    };
    image: string;
    brandlogo: string;
    environnementdesc: string;
    ethicaldesc: string;
    branddesc: string;
    price: number;
    rgb: string;
    ethical_score: number;
    ecological_score: number;
    local_score: number;
}



const Result = () => {
    const [isLoading, setLoading] = useState(false);
    const [itemList, setItemList] = useState<Items[]>([]);
    const [filteredItemList, setFilteredItemList] = useState<Items[]>([]);
    const [cookies] = useCookies(['token']);
    const location = useLocation();
    const { url } = location.state || {};

    useEffect(() => {
        const fetchData = async () => {
            if (url) {
                setLoading(true);
                try {
                    const { data } = await client.query({
                        query: gql`
                            query Alternative($url: String!) {
                                findAlternative (
                                    URL: $url, 
                                    likeliness: 0.8, 
                                    minPrice: 0, 
                                    maxPrice: 100
                                ) {
                                    status
                                    devMessage
                                    articles {
                                        id
                                        name
                                        brand
                                        redirection_url
                                        email
                                        country { name }
                                        material { name }
                                        transport { name }
                                        image
                                        brandlogo
                                        environnementdesc
                                        ethicaldesc
                                        branddesc
                                        price
                                        rgb
                                        ethical_score
                                        ecological_score
                                        local_score
                                    }
                                }
                            }
                        `,
                        variables: { url }
                    });
                    const articles = data.findAlternative.articles.map((article: Article) => ({
                        id: article.id,
                        title: article.name,
                        photo: article.image,
                        price: article.price,
                        desc: `${article.branddesc} - ${article.environnementdesc}`,
                        score: article.ethical_score,
                        type: article.material.name,
                        redirectnumber: 0,
                        lastshown: new Date(),
                        lastclick: new Date(),
                        ethicalscore: article.ethical_score,
                        ecologicalscore: article.ecological_score,
                        redirection_url: article.redirection_url
                    }));
                    setItemList(articles);
                    setFilteredItemList(articles);
                    console.log(articles);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [url]);

    return (
        <>
            <BlurryBackground />
            <Loading open={isLoading} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '80vh',
                marginTop: '20vh',
                overflowY: 'auto',
            }}>
                {filteredItemList.map((item) => (
                    <div key={item.id} style={{ marginBottom: '30px' }}>
                        <AlternativeCard
                            key={item.id}
                            title={item.title}
                            type={item.type}
                            photo={item.photo}
                            id={item.id}
                            price={item.price}
                            desc={item.desc}
                            score={item.score}
                            ecologicalscore={item.ecologicalscore}
                            ethicalscore={item.ethicalscore}
                            redirectnumber={item.redirectnumber}
                            lastshown={item.lastshown}
                            lastclick={item.lastclick}
                            redirection_url={item.redirection_url} />
                    </div>
                ))}
            </Box>
        </>
    );
};

export default Result;
